<template>
  <v-container>
    <v-row no-gutters="">
      <v-col cols="12">
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
};
</script>

<style></style>
